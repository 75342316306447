import $ from "jquery";

export default {
    /*
     * Creator toggles this feature
     */
    enableProjectEmailSubscriptions(project_id, payload) {
        return $.ajax({
            method: 'post',
            processData: false,
            contentType: false,
            cache: false,
            url: `/${project_id}/config/email-subscriptions`,
            data : payload,
        });
    },
    enableUserEmailSubscription(email_subscription_id) {
        return $.ajax({
            method: 'put',
            url: `/p/email-subscriptions/${email_subscription_id}/enable`,
        });
    },
    disableUserEmailSubscription(email_subscription_id) {
        return $.ajax({
            method: 'put',
            url: `/p/email-subscriptions/${email_subscription_id}/disable`,
        });
    },
}