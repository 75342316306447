import tinymce from 'tinymce/tinymce';
import 'tinymce/themes/silver/theme';
import 'tinymce/icons/default/icons';
import 'tinymce/plugins/autoresize/plugin.min.js';
import 'tinymce/plugins/media/plugin.min.js';
import 'tinymce/plugins/image/plugin.min.js';
import 'tinymce/plugins/link/plugin.min.js';
import 'tinymce/plugins/lists/plugin.min.js';
import 'tinymce/models/dom/model.js';

var uploadScriptTinyMCE = (blobInfo, progress) => new Promise((resolve, reject) => {

  const xhr = new XMLHttpRequest();
  xhr.withCredentials = false;
  xhr.open('POST', '/p/account/project-post-image-upload');

  xhr.upload.onprogress = (e) => {
    progress(e.loaded / e.total * 100);
  };

  xhr.onload = () => {
    if (xhr.status === 403) {
      reject({ message: 'HTTP Error: ' + xhr.status, remove: true });
      return;
    }

    if (xhr.status < 200 || xhr.status >= 300) {
      reject('HTTP Error: ' + xhr.status);
      return;
    }

    const json = JSON.parse(xhr.responseText);

    if (!json || typeof json.location != 'string') {
      reject('Invalid JSON: ' + xhr.responseText);
      return;
    }

    resolve(json.location);
  };

  xhr.onerror = () => {
    reject('Image upload failed due to a XHR Transport error. Code: ' + xhr.status);
  };

  const formData = new FormData();
  formData.append('tinymce_file_upload', blobInfo.blob(), blobInfo.filename());

  xhr.send(formData);
});
  
tinymce.init({
  selector: '.tinymce',
  model: 'dom',
  plugins: 'autoresize image link lists',
  autoresize_bottom_margin: 20,
  skin: 'comradery',
  content_css: 'build/skins/content/comradery/content.min.css',
  min_height: 300,
  toolbar_sticky: true,
  contextmenu: 'link image paste',
  toolbar: ' undo redo|formatselect | bold italic | alignleft aligncenter alignright alignjustify | outdent indent | image link bullist numlist | hr',
  block_formats: 'Paragraph=p; Header 1=h1; Header 2=h2; Header 3=h3; Header 4=h4; Header 5=h5; Header 6=h6',
  menubar: false,
  media_dimensions: false,
  object_resizing : false,
  image_uploadtab: true,
  images_upload_handler: uploadScriptTinyMCE,
  images_file_types: 'jpeg,jpg,png,gif',
  automatic_uploads: true,
  image_dimensions: false,
  image_class_list: [
    {title: 'Regular', value: 'tinymce_full_width img-fluid'}
  ],
  relative_urls : false,
  document_base_url: "/",
  setup: function(editor) {
    editor.on('init', function () {
      editor.getContainer().style.transition='border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out';
    }).on('focus', function(e) {
        editor.getContainer().style.boxShadow='0 0 0 .2rem rgb(54 97 11 / 25%)';
    }).on('blur', function(e){
        editor.getContainer().style.boxShadow='';
    }).on('keyup', function(e){

    });
  }
});
