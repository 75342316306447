var loadingspinner = $('<span class="ms-_5 spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>');

var alert_el = $("<div class='alert alert-dismissible fade show' role='alert'></div>");

var alert_button = $("<button type='button' class='btn-close' data-bs-dismiss='alert' aria-label='Close'></button>");

$(document).on("click", "#update-card-address-btn", function(){
    var billing1 = $("#billing-address-1-update").val(),
        billing2 = $("#billing-address-2-update").val(),
        billing_country = $("#billing-country-update").val(),
        billing_city = $("#billing-city-update").val(),
        billing_state = $("#billing-state-update").val(),
        billing_zipcode = $("#billing-zipcode-update").val();

    var form = new FormData();
    var btn = $(this);

    form.set("billing_country", billing_country);
    form.set("billing1", billing1);
    form.set("billing2", billing2);
    form.set("billing_city", billing_city);
    form.set("billing_state", billing_state);
    form.set("billing_zipcode", billing_zipcode);

    btn.prop("disabled", true).append(loadingspinner);

    // TODO: handle 400 and 500 status codes
    $.ajax({
        url : '/p/address/update',
        data : form,
        method : 'POST',
        processData: false,
        contentType: false,
        success : function(data){
            if(data == "fill info")
            {
                alert_el.text("You must fill all applicable address fields.").removeClass("alert-danger alert-info alert-success").addClass("alert-danger");
                alert_el.append(alert_button).addClass('fade show');
                $("#card-address-update").prepend(alert_el);

                btn.prop("disabled", false).find(".spinner-border").remove();
            }
            else if(data == "card updated")
            {
                $("#subscribe-to-service").prop("disabled", false).find(".spinner-border").remove();
                $("#card-address-update").trigger("reset");

                $("#update-card-address").find(".btn-close").trigger("click");

                if($("#alert-update-address").length)
                {
                    $("#alert-update-address").html("Card address updated.").removeClass("alert-danger").addClass("alert-success");
                }
            }
            
            if(data.error)
            {
                alert_el.text("There seems to be some issues. Update your address and try again.").removeClass("alert-danger alert-info alert-success").addClass("alert-danger");
                alert_el.append(alert_button).addClass('fade show');
                $("#card-address-update").prepend(alert_el);

                btn.prop("disabled", false).find(".spinner-border").remove();
                console.log(data.error);

                if(data.error.reason == "missing address parts")
                {
                    //Get details from here for different kinds of types https://developers.google.com/maps/documentation/geocoding/requests-geocoding#results
                    data.error.unconfirmed.forEach(item => {
                        //This means the street number or address is not accurately represented.
                        if(item == "street_number" || item == "street_address")
                        {
                            $("#billing-address-1-update").addClass("is-invalid").attr("aria-invalid", true).attr("aria-errormessage", "billing-address-1-error-message").closest(".form-group").append("<div id='billing-address-1-error-message' class='invalid-feedback' role='alert'>Double check that this is correct and try again.</div>");
                        }

                        //Second line couldn't be verified.
                        if(item == "premise")
                        {
                            $("#billing-address-2-update").addClass("is-invalid").attr("aria-invalid", true).attr("aria-errormessage", "billing-address-2-error-message").closest(".form-group").append("<div id='billing-address-2-error-message' class='invalid-feedback' role='alert'>Double check that this is correct and try again.</div>");
                        }
                        
                        //city couldn't be verified
                        if(item == "locality")
                        {
                            $("#billing-city-update").addClass("is-invalid").attr("aria-invalid", true).attr("aria-errormessage", "billing-city-error-message").closest(".form-group").append("<div id='billing-city-error-message' class='invalid-feedback' role='alert'>Double check that this is correct and try again.</div>");
                        }
                        
                        //postal code couldn't be verified.
                        if(item == "postal_code")
                        {
                            $("#billing-zipcode-update").addClass("is-invalid").attr("aria-invalid", true).attr("aria-errormessage", "billing-zipcode-error-message").closest(".form-group").append("<div id='billing-zipcode-error-message' class='invalid-feedback' role='alert'>Double check that this is correct and try again.</div>");
                        }
                    });

                    data.error.addressComponents.forEach(item => {
                        if(item.spellCorrected)
                        {
                            if(item.componentType == "street_address")
                            {
                                $("#billing-address-1-update").val(addressComponents[0].componentName.text + " " + item.componentName.text);
                            }

                            //Second line couldn't be verified.
                            if(item.componentType == "premise")
                            {
                                $("#billing-address-2-update").val(item.componentName.text);
                            }
                            
                            //city couldn't be verified
                            if(item.componentType == "locality")
                            {
                                $("#billing-city-update").val(item.componentName.text);
                            }
                            
                            //postal code couldn't be verified.
                            if(item.componentType == "postal_code")
                            {
                                $("#billing-zipcode-update").val(item.componentName.text);
                            }
                        }
                    });
                }
            }
            if(data.confirm)
            {
                var assembleAddressNew ="";
                var formResponse = $("#address-api-updated").find("form");

                $("input[name='googleResponseIdConfirm").val(data.confirm.googleResponseId);

                data.confirm.addressComponents.forEach(item => {
                    if(item.componentType == "postal_code_suffix")
                    {
                        formResponse.find("input[name=address-confirm-zipcode]").val(function() {
                            let splitZip = this.value.split("-");
                            splitZip[1] = item.componentName.text;
                            return splitZip.join("-");
                        });
                    }

                    if(item.spellCorrected)
                    {
                        assembleAddressNew += "<b>";
                    }

                    if(item.componentType == "postal_code")
                    {
                        assembleAddressNew += ", ";
                        formResponse.find("input[name=address-confirm-zipcode]").val(function() {
                            let splitZip = this.value.split("-");
                            splitZip[0] = item.componentName.text;
                            return splitZip.join("-");
                        });
                    }

                    assembleAddressNew += item.componentName.text;

                    if(item.componentType == "street_number")
                    {
                        assembleAddressNew += " ";
                        formResponse.find("input[name=address-confirm-billing-1]").val(item.componentName.text);
                    }

                    if(item.componentType == "locality")
                    {
                        assembleAddressNew += ", ";
                        formResponse.find("input[name=address-confirm-city]").val(item.componentName.text);
                    }

                    if(item.componentType == "country")
                    {
                        formResponse.find("input[name=address-confirm-country]").val(data.confirm.regionCode);
                    }

                    if(item.componentType == "administrative_area_level_1")
                    {
                        formResponse.find("input[name=address-confirm-state]").val(item.componentName.text);
                    }

                    if(item.spellCorrected)
                    {
                        assembleAddressNew += "</b>";
                    }

                    if(item.componentType == "route")
                    {
                        formResponse.find("input[name=address-confirm-billing-1]").val(formResponse.find("input[name=address-confirm-billing-1]").val() + " " + item.componentName.text);
                        assembleAddressNew += "<br />";
                    }

                    if(item.componentType == "postal_code")
                    {
                        assembleAddressNew += "<br />";
                    }
                });
                
                $("#address-api-updated").prepend("<div id='confirm-address-block' class='bg-light p-2'><b>Confirm your address</b><br/><br/>"+assembleAddressNew+"</div>").find("form").removeClass("d-none");
                btn.prop("disabled", false).hide().find(".spinner-border").remove();
                $("#card-address-update").hide();
            }

            if(data.updated)
            {
                $("#update-form-feedback input").not(':button, :submit, :reset, :hidden').val('');
                $("#update-form-feedback").addClass("d-none");
                btn.prop("disabled", false).find(".spinner-border").remove();
                location.href = location.href; // Also resets scroll position in window
            }
        }
    });
});

$(document).on("click", "#confirm-address-updated-google", function(e){
    e.preventDefault;
    e.stopPropagation;

    var billing1 = $("input[name=address-confirm-billing-1]").val(),
        billing2 = $("input[name=address-confirm-billing-2]").val(),
        billing_country = $("input[name=address-confirm-country]").val(),
        billing_city = $("input[name=address-confirm-city]").val(),
        billing_state = $("input[name=address-confirm-state]").val(),
        billing_zipcode = $("input[name=address-confirm-zipcode]").val()
        billing_google_response = $("input[name=googleResponseIdConfirm]").val();

    var form = new FormData();
    var btn = $(this);

    form.set("billing_country", billing_country);
    form.set("billing1", billing1);
    form.set("billing2", billing2);
    form.set("billing_city", billing_city);
    form.set("billing_state", billing_state);
    form.set("billing_zipcode", billing_zipcode);
    form.set("billing_google_response_id", billing_google_response);

    btn.prop("disabled", true).append(loadingspinner);

    // TODO: handle 400's and 500's
    $.ajax({
        url : '/p/address/update',
        data : form,
        method : 'POST',
        processData: false,
        contentType: false,
        success : function(data){
            if(data.updated)
            {
                $("#update-form-feedback input").not(':button, :submit, :reset, :hidden').val('');
                $("#update-form-feedback").addClass("d-none");
                btn.prop("disabled", false).hide().find(".spinner-border").remove();
                $("#confirm-address-block").remove();
                location.href = location.href;
            }

            if(data.error)
            {

            }
        }
    });
});
