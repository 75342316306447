var loadingspinner = $('<span class="ms-_5 spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>');

var alert_el = $("<div class='alert alert-dismissible fade show' role='alert'></div>");

var alert_button = $("<button type='button' class='btn-close' data-bs-dismiss='alert' aria-label='Close'></button>");

import { urlifyString } from './urlify.js';

$(window).on("load", function() {
    $(".project-post-publish, .project-post-draft").on("click", function(e){
        e.preventDefault();

        var btn = $(this);

        btn.append(loadingspinner).prop('disabled', true);

        var form = new FormData();

        var notification = $('#post-notification');

        form.set("post-title", $("#post-title").val());
        form.set("post-project", $("#project-id").val());
        form.set("post-slug", $("#unique-url-input").val());

        if($("#post-image-upload").length)
        {
            var file = $("#post-image-upload");

            if(btn.hasClass("project-post-publish"))
            {
                if(file.val() === 0)
                {
                    alert_el.text("You need to upload a photo to publish.").removeClass("alert-danger alert-info alert-success").addClass("alert-danger");
                    alert_el.append(alert_button).addClass('fade show');

                    $("#notifications").append(alert_el);
                    return;
                }
            }

            form.set("post-hero", file[0].files[0]);
            form.set("post-type", "image");
        }
        else if($("#post-type").length)
        {
            if($("#post-type").val() == "text")
            {
                form.set("post-hero", null);
                form.set("post-type", "text");
            }
            else if($("#post-type").val() == "video")
            {
                function getId(url) {
                    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
                    var match = url.match(regExp);

                    return (match && match[2].length === 11)
                    ? match[2]
                    : null;
                }

                var embedHtml = '<iframe width="100%" height="auto" src="//www.youtube.com/embed/'+getId($("#post-video-embed").val())+'" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';

                form.set("post-hero", $("#post-video-embed").val());
                form.set("post-type", "video");
            }
            else if($("#post-type").val() == "livestream")
            {
                function getId(url) {
                    var regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
                    var match = url.match(regExp);

                    return (match && match[2].length === 11)
                    ? match[2]
                    : null;
                }

                var embedHtml = '<iframe width="100%" height="auto" src="//www.youtube.com/embed/'+getId($("#post-live-embed").val())+'" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';

                form.set("post-hero", $("#post-live-embed").val());
                form.set("post-type", "livestream");
            }
            else if($("#post-type").val() == "audio")
            {
                form.set("post-hero", $("#post-audio-embed").val());
                form.set("post-type", "audio");
                var file = $("#post-cover-upload");
                form.set("post-cover", file[0].files[0]);
            }
            else if($("#post-type").val() == "podcast")
            {
                form.set("post-hero", $("#post-podcast-embed").val());
                form.set("post-type", "podcast");
                var file = $("#post-cover-upload");
                form.set("post-cover", file[0].files[0]);
            }
        }

        if(btn.hasClass("project-post-publish"))
        {
            if($("#post-image-upload, #post-video-embed, #post-live-embed, #post-audio-embed, #post-podcast-embed").val() === 0)
            {
                alert_el.text("You're missing a content type. Add one before publishing.").removeClass("alert-danger alert-info alert-success").addClass("alert-danger");
                alert_el.append(alert_button).addClass('fade show');

                $("#notifications").append(alert_el);
                return;
            }
        }

        form.set("post-content", tinymce.get("post-project").getContent());

        if(btn.hasClass("project-post-draft") && $("#post-id").length)
        {
            form.set("post-id", $("#post-id").val());
        }

        if(btn.hasClass("project-post-draft"))
        {
            $.ajax({
                url : '/p/account/project-post-draft',
                data : form,
                method : 'POST',
                processData: false,
                contentType: false,
                success : function(data){
                    btn.prop("disabled", false).find(".spinner-border").remove();

                    if(data == "no access")
                    {
                        alert_el.text("Oops you do not have access to post this").removeClass("alert-danger alert-info alert-success").addClass("alert-danger");
                        alert_el.append(alert_button).addClass('fade show');

                        $("#notifications").append(alert_el);
                    }
                    else if(data == "hero failed upload")
                    {
                        alert_el.text("Oops, hero didn't upload, try again.").removeClass("alert-danger alert-info alert-success").addClass("alert-danger");
                        alert_el.append(alert_button).addClass('fade show');

                        $("#notifications").append(alert_el);
                    }
                    else if(data == "invalid post type")
                    {
                        alert_el.text("Oops, your post type is invalid. Refresh the page and try again.").removeClass("alert-danger alert-info alert-success").addClass("alert-danger");
                        alert_el.append(alert_button).addClass('fade show');

                        $("#notifications").append(alert_el);
                    }
                    else if(data == "unpublished updated")
                    {
                        alert_el.text("Post saved unpublished.").removeClass("alert-danger alert-info alert-success").addClass("alert-success");
                        alert_el.append(alert_button).addClass('fade show');

                        $("#notifications").append(alert_el);
                    }
                    else if(data == "draft updated")
                    {
                        alert_el.text("Draft has been updated!").removeClass("alert-danger alert-info alert-success").addClass("alert-success");
                        alert_el.append(alert_button).addClass('fade show');

                        $("#notifications").append(alert_el);
                    }
                    else if(data == "empty title")
                    {
                        alert_el.text("You need to have a post title at least to save a draft.").removeClass("alert-danger alert-info alert-success").addClass("alert-danger");
                        alert_el.append(alert_button).addClass('fade show');

                        $("#notifications").append(alert_el);
                    }
                    else
                    {
                        alert_el.text("Draft has been saved!").removeClass("alert-danger alert-info alert-success").addClass("alert-success");
                        alert_el.append(alert_button).addClass('fade show');

                        $("#post-new-form").append("<input type='hidden' id='post-id' value='"+data+"' />");

                        $("#notifications").append(alert_el);
                    }
                }
            });
        }
        else
        {
            if($(".form-check-input[name=post-supporter-type]").length)
            {
                var publishType = $(".form-check-input[name=post-supporter-type]:checked").val();
            }
            else
            {
                var publishType = 0;
            }

            if(publishType == "selectTiers")
            {
                form.set("publish-option", "0");
                var arr = [];
                $(".tier-lock-selection:checked").each(function(){
                    arr.push($(this).val())
                });

                form.append("tiers-selected", JSON.stringify(arr));
            }
            else
            {
                form.set("publish-option", publishType);
            }


            $.ajax({
                url : '/p/account/project-post-publish',
                data : form,
                method : 'POST',
                processData: false,
                contentType: false,
                success : function(data){
                    btn.prop("disabled", false).find(".spinner-border").remove();

                    if(data == "no access")
                    {
                        alert_el.text("Oops you do not have access to post this").removeClass("alert-danger alert-info alert-success").addClass("alert-danger");
                        alert_el.append(alert_button).addClass('fade show');

                        $("#notifications").append(alert_el);
                    }
                    else if(data == "empty content")
                    {
                        alert_el.text("You need to have a post title and post content.").removeClass("alert-danger alert-info alert-success").addClass("alert-danger");
                        alert_el.append(alert_button).addClass('fade show');

                        $("#notifications").append(alert_el);
                    }
                    else if(data == "tier doesn't exist"){
                        alert_el.text("The tier you selected doesn't work. Try again.").removeClass("alert-danger alert-info alert-success").addClass("alert-danger");
                        alert_el.append(alert_button).addClass('fade show');

                        $("#notifications").append(alert_el);
                    }
                    else
                    {
                        window.location.href = data;
                    }
                }
            });
        }

    });

    // tinymce.get('post-project').on("keyup", function(){
    //     AUTO SAVE FEATURE FOR ANOTHER TIME
    //
    //     clearTimeout(timeout);
    //     var elem = $(this);
    //
    //     timeout = setTimeout(function () {
    //         var form = new FormData();
    //
    //         form.set("post-content", tinymce.get("post-project").getContent());
    //         form.set("post-title", $("#post-title").val());
    //         form.set("post-project", $("#project-id").val());
    //
    //         $.ajax({
    //             url : '/p/account/project-post-save-draft',
    //             data : form,
    //             method : 'POST',
    //             processData: false,
    //             contentType: false,
    //             success : function(data){
    //                 if(data == "saved"){
    //                     console.log("Saved!");
    //                 }
    //             }
    //         });
    //     }, 15000);
    // });

    $(".project-post-edit-publish").on("click", function(e){
        e.preventDefault();

        var btn = $(this);

        btn.append(loadingspinner).prop('disabled', true);

        var form = new FormData();

        var notification = $('#post-notification');

        form.set("post-content", tinymce.get("post-project").getContent());
        form.set("post-title", $("#post-title").val());
        form.set("post-project", $("#project-id").val());
        form.set("post-slug", $("#unique-url-input").val());
        form.set("post-id", btn.attr("data-post-id"));

        if($("#post-image-upload").length)
        {
            var file = $("#post-image-upload");

            if(btn.hasClass("project-post-publish"))
            {
                if(file.val() === 0)
                {
                    alert_el.text("You need to upload a photo to publish.").removeClass("alert-danger alert-info alert-success").addClass("alert-danger");
                    alert_el.append(alert_button).addClass('fade show');

                    $("#notifications").append(alert_el);
                    return;
                }
            }

            form.set("post-hero", file[0].files[0]);
            form.set("post-type", "image");
        }

        if($("#post-type").val() == "text")
        {
            form.set("post-hero", null);
            form.set("post-type", "text");
        }
        else if($("#post-type").val() == "video")
        {
            function getId(url) {
                const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
                const match = url.match(regExp);

                return (match && match[2].length === 11)
                ? match[2]
                : null;
            }

            var embedHtml = '<iframe width="100%" height="auto" src="//www.youtube.com/embed/'+getId($("#post-video-embed").val())+'" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';

            form.set("post-hero", embedHtml);
            form.set("post-type", "video");
        }
        else if($("#post-type").val() == "livestream")
        {
            function getId(url) {
                const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
                const match = url.match(regExp);

                return (match && match[2].length === 11)
                ? match[2]
                : null;
            }

            var embedHtml = '<iframe width="100%" height="auto" src="//www.youtube.com/embed/'+getId($("#post-video-embed").val())+'" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>';

            form.set("post-hero", embedHtml);
            form.set("post-type", "livestream");
        }
        else if($("#post-type").val() == "audio")
        {
            form.set("post-hero", $("#post-audio-embed").val());
            form.set("post-type", "audio");
            var file = $("#post-cover-upload");
            form.set("post-cover", file[0].files[0]);
        }
        else if($("#post-type").val() == "podcast")
        {
            form.set("post-hero", $("#post-podcast-embed").val());
            form.set("post-type", "podcast");
            var file = $("#post-cover-upload");
            form.set("post-cover", file[0].files[0]);
        }

        if($(".form-check-input[name=post-supporter-type]").length)
        {
            var publishType = $(".form-check-input[name=post-supporter-type]:checked").val();
        }
        else
        {
            var publishType = null;
        }

        if(publishType == "selectTiers")
        {
            form.set("publish-option", "0");
            var arr = [];
            $(".tier-lock-selection:checked").each(function(){
                arr.push($(this).val())
            });

            form.append("tiers-selected", JSON.stringify(arr));
        }
        else
        {
            form.set("publish-option", publishType);
        }

        $.ajax({
            url : '/p/account/project-edit-post-publish',
            data : form,
            method : 'POST',
            processData: false,
            contentType: false,
            success : function(data){
                btn.prop("disabled", false).find(".spinner-border").remove();

                if(data == "no access")
                {
                    alert_el.text("Oops you do not have access to edit this post").removeClass("alert-danger alert-info alert-success").addClass("alert-danger");
                    alert_el.append(alert_button).addClass('fade show');

                    $("#notifications").append(alert_el);
                }
                else if(data == "empty content")
                {
                    alert_el.text("You need to have a post title and post content.").removeClass("alert-danger alert-info alert-success").addClass("alert-danger");
                    alert_el.append(alert_button).addClass('fade show');

                    $("#notifications").append(alert_el);
                }
                else if(data == "no hero image")
                {
                    alert_el.text("You need to upload a photo to publish.").removeClass("alert-danger alert-info alert-success").addClass("alert-danger");
                    alert_el.append(alert_button).addClass('fade show');

                    $("#notifications").append(alert_el);
                }
                else
                {
                    window.location.href = data;
                }
            }
        });
    });

    $(".project-delete-post").on("click", function(e){
        e.preventDefault();

        var btn = $(this);

        btn.append(loadingspinner).prop('disabled', true);

        var postId = $(this).attr("data-post-id");
        var form = new FormData();

        form.set("delete-project-post", postId);

        $.ajax({
            url : '/p/account/project-delete-post',
            data : form,
            method : 'POST',
            processData: false,
            contentType: false,
            success : function(data){
                if(data == "success")
                {
                    if(btn.parents(".post-list-item").length)
                    {
                        btn.closest(".post-list-item").remove();
                    }
                    else
                    {
                        var listBtn =  $(".btn-trigger-modal-confirmation-delete[data-post-id="+postId+"]");
                        listBtn.closest(".post-list-item").remove();
                        btn.prop("disabled", false).find(".spinner-border").remove();

                        var myModalConfirmation = document.getElementById('areYouSureModal');
                        var modalConfirmation = bootstrap.Modal.getInstance(myModalConfirmation);
                        modalConfirmation.hide();
                    }

                    if($("#dashboard-post-count").length)
                    {
                        $("#dashboard-post-number").text('(' + $(".post-list-item").length + ')');
                    }

                    if($(".post-list-item").length == 0)
                    {
                        if($("#dashboard-post-count").length)
                        {
                            $("#dashboard-post-count").remove();
                        }
                    }
                }
                else
                {
                    btn.prop("disabled", false).find(".spinner-border").remove();
                }
            }
        });
    });

    $(document).on("click", ".project-pin-post", function(){
        var form = new FormData();
        var btn = $(this);

        form.set("project-pin-post", btn.attr("data-post-id"));

        btn.append(loadingspinner).prop('disabled', true);

        var thisList = btn.closest(".post-list-item");

        var post_id = thisList.find(".project-delete-post").attr("data-post-id");

        $.ajax({
            url : '/p/account/project-pin-post',
            data : form,
            method : 'POST',
            processData: false,
            contentType: false,
            success : function(data){
                if(data.success)
                {
                    $(".post-list-item").each(function(){
                        var list = $(this);

                        if(list.find(".draft-badge").length)
                        {
                            return;
                        }

                        if(list.find(".project-pin-post").length)
                        {
                            return;
                        }

                        list.find(".btn-containers-list").prepend('<button data-post-id="'+post_id+'" class="btn btn-link project-pin-post">Pin</button>');
                    });

                    if($(".pinned-badge").length)
                    {
                        var badge = $(".pinned-badge");

                        badge.detach().appendTo(thisList.find(".post-descriptor-row"));
                    }   
                    else
                    {
                        thisList.find(".post-descriptor-row").append('<span class="badge pinned-badge bg-dark ms-1 text-light">Pinned</span>');
                    }

                    $(".project-unpin-post").remove();

                    thisList.find(".btn-containers-list").prepend('<button data-post-id="'+post_id+'" class="btn btn-link project-unpin-post">Unpin</button>');

                    btn.remove();
                }
                else
                {
                    btn.prop("disabled", false).find(".spinner-border").remove();
                }
            }
        });
    });

    $(document).on("click", ".project-unpin-post", function(){
        var form = new FormData();
        var btn = $(this);

        form.set("project-unpin-post", btn.attr("data-post-id"));

        btn.append(loadingspinner).prop('disabled', true);

        var thisList = btn.closest(".post-list-item");

        $.ajax({
            url : '/p/account/project-unpin-post',
            data : form,
            method : 'POST',
            processData: false,
            contentType: false,
            success : function(data){
                if(data.success)
                {
                    thisList.find(".pinned-badge").remove();

                    var post_id = thisList.find(".project-delete-post").attr("data-post-id");

                    thisList.find(".btn-containers-list").prepend('<button data-post-id="'+post_id+'" class="btn btn-link project-pin-post">Pin</button>');

                    btn.remove();
                }
                else
                {
                    btn.prop("disabled", false).find(".spinner-border").remove();
                }
            }
        });
    });

    $(document).on("click", ".btn-trigger-modal-confirmation-delete", function(){
        var btn = $(this);
        var postId = btn.attr("data-post-id");

        $("#areYouSureModal .project-delete-post").attr("data-post-id", postId);
    });
});

// Everything below is to do a URL update on a string. This checks for keystrokes and realtime typing
var urlFriendlyKeycodes = [8, 9, 
    32, 38, 
    40, 46, 48, 49, 
    50, 51, 52, 53, 54, 55, 56, 57, 
    65, 66, 67, 68, 69, 
    70, 71, 72, 73, 74, 75, 76, 77, 78, 79, 
    80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 
    90,
    173,    
];
    
var urlFriendlyInput = document.querySelector("#unique-url-input");

if(urlFriendlyInput)
{
    urlFriendlyInput.addEventListener("keydown", function(e){
        if(!urlFriendlyKeycodes.includes(e.which))
        {
            e.preventDefault();
        }
    });
    
    var typingTimer;                //timer identifier
    var doneTypingInterval = 500;  //time in ms, 5 seconds for example
    
    urlFriendlyInput.addEventListener("keyup", function(){
        clearTimeout(typingTimer);
        typingTimer = setTimeout(doneTyping, doneTypingInterval);
    });
    
    function doneTyping(){
        urlFriendlyInput.value = urlifyString("#unique-url-input");
    }
}
