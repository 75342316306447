import '../sass/bootstrap/custom.scss';
import EmailSubscriptionApi from "./api/email-subscription";

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
const $ = require('jquery');
const autosize = require('./autosize.min.js');

import './jquery.addressfield.js';
const addjsonfield = require('./addressfield.jquery.json');
import './jquery.typewatch.js';

//address validation js
import './address-validation.js';

//TinyMCE js
import './tinymce.js';

//Fittext js
import './jquery.fittext.js';

import plusicon from '../images/icons/plus.svg';
import checkicon from '../images/icons/check.svg';

var loadingspinner = $('<span class="ms-_5 spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>');

var alert_el = $("<div class='alert alert-dismissible fade show' role='alert'></div>");

var alert_button = $("<button type='button' class='btn-close' data-bs-dismiss='alert' aria-label='Close'></button>");

import './project-posts.js';

function validURL(str) {
  var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
  return !!pattern.test(str);
}

function daysInThisMonth() {
    var now = new Date();
    return new Date(now.getFullYear(), now.getMonth()+1, 0).getDate();
}

autosize($('textarea.autoresize'));

$(window).on("load", function() {
    jQuery("#responsive_headline").fitText();

    $("textarea.is-resizable").each(function () {
      this.setAttribute("style", "height:" + (this.scrollHeight) + "px;overflow-y:hidden;resize:none;");
    }).on("input", function () {
      this.style.height = "auto";
      this.style.height = (this.scrollHeight) + "px";
    });

    $('#card-address-form').addressfield({
      json: addjsonfield,
      fields: {
        thoroughfare: '#billing-address-1',
        premise :'#billing-address-2',
        country: 'select#billing-country',
        locality: '#locality-fields',
        localityname: '#billing-city',
        administrativearea: '.billing-state',
        postalcode: '.billing-zipcode'
      },
    });

    $('#card-address-update').addressfield({
      json: addjsonfield,
      fields: {
        thoroughfare: '#billing-address-1-update',
        premise :'#billing-address-2-update',
        country: 'select#billing-country-update',
        locality: '#locality-fields-update',
        localityname: '#billing-city-update',
        administrativearea: '#billing-state-update',
        postalcode: '#billing-zipcode-update'
      },
    });

    $('#edit-card-address-form').addressfield({
      json: addjsonfield,
      fields: {
        thoroughfare: '#billing-address-1-edit',
        premise :'#billing-address-2-edit',
        country: 'select#billing-country-edit',
        locality: '#locality-fields-edit',
        localityname: '#billing-city-edit',
        administrativearea: '#billing-state-edit',
        postalcode: '#billing-zipcode-edit'
      },
    });

    $("#project-donation-only, .donation-tier").on("keyup", function(){
        let amount = $(this).val().replace(/[^0-9]/g, '');
        let parent = $(this).closest(".card");
        let btn = parent.find(".btn");

        let url = new URL(btn.attr("href"));
        url.searchParams.set('donation', amount)

        btn.attr("href", url);
    });

    $(".password-reveal").on("click", function(e){

        var parent = $(this).siblings("input");

        if(parent.attr("type") == "text")
        {
            $(this).text("Show");
            parent.attr("type", "password");
        }
        else
        {
            $(this).text("Hide");
            parent.attr("type", "text");
        }
    });

    $("[data-type=currency]").each(function(){
        $(this).inputmask(
            'decimal',
                { 'alias': 'numeric',
                    'groupSeparator': ',',
                    'autoGroup': true,
                    'digits': 0,
                    'radixPoint': ".",
                    'digitsOptional': false,
                    'allowMinus': false,
                    'prefix': '$',
                    'placeholder': '0',
                    'rightAlign': false,
                    'nullable' : false,
                    'min' : this.hasAttribute('data-amount-minimum') ? $(this).attr('data-amount-minimum') : 1
                }
        );
    });

    $("[data-type=auth]").each(function(){
        $(this).inputmask(
            { mask: "[999 999]", greedy: true }
        )
    });

    $(document).on("click", function (e){
        if($(event.target).closest("#user_info").length)
        {
            $("#user_info").toggleClass("is_open");
        }
        else
        {
            $("#user_info").removeClass("is_open");
        }
    });

    setTimeout(function(){
        $(".accordian__content").each(function(){
            $(this).data("height", $(this).outerHeight()).css({
                "height": 0,
                "display": "block"
            });
        });
    }, 200);

    $("[data-toggler]").click(function(){
        var hide = $(this).attr("data-hide");
        var show = $(this).attr("data-toggler");

        $(show).each(function(){
            if($(this).hasClass("hide__lg"))
            {
                $(this).removeClass("hide__lg");
            }
            else{
                $(this).show();
            }
        });

        $(hide).hide();
    });

    $("[data-modal-toggle]").click(function(){
        $("#modal-bg").css("visibility", "visible");
        $("#modal-bg").animate({
            "opacity" : "1"
        }, 1000);
    });

    $("#modal-header .icon").click(function(){
        $("#modal-bg").animate({
            "opacity" : "0"
        }, 1000, "swing", function(){
            $("#modal-bg").css("visibility", "hidden");
        });
    });

    $(".accordian__heading").on("click", function(){
        var accordian = $(this).siblings(".accordian__content");
        var height = accordian.data("height");
        $(this).addClass("is__open");

        if(accordian.height() == 0)
        {
            accordian.animate({
                "height" : height
            });

            $(this).closest(".accordian").find('.accordian__content').not(accordian).animate({
                "height" : 0
            }).siblings(".accordian__heading").removeClass("is__open");
        }
        else
        {
            accordian.animate({
                "height" : 0
            });

            $(this).removeClass("is__open");

        }
    });

    $(".alert .icon-x").on("click", function(){
        $(this).closest(".alert").remove();
    });

    $("[anchor]").click(function(e){

        var goto = $(this).attr("anchor");

        var pos = $(goto).offset().top;

        if($(".subnav").length > 0)
        {
            pos = pos - $(".subnav").outerHeight();
        }

        $("html, body").animate({
            scrollTop : pos
        }, "400", "linear");

        if(!$(this).hasClass("no-hash"))
        {
            window.location.hash = goto.replace("#", "");
        }

        e.preventDefault();
    });

    if($(location).attr("hash"))
    {
        var hash = $(location).attr("hash");

        var pos = $(hash).offset().top;

        if($(".subnav").length > 0)
        {
            pos = pos - $(".subnav").outerHeight();
        }

        $("html, body").animate({
            scrollTop : pos
        }, "400", "linear");
    }

    $("[data-type=uri]").on("keydown", function(e){
        urlify($(this), e);
    });

    $(".input__text-pre").each(function(){
        var width = $(this).outerWidth();
        $(this).siblings("input").css({
            "padding-left" : width
        });

        console.log(width);
    });

    $("#mobile-nav-trigger").on("click", function(){
        if($(this).hasClass("is__open"))
        {
            var $element = $(this);
            $element.removeClass("is__open").addClass("is__static");

            $element.one('webkitAnimationEnd oanimationend msAnimationEnd animationend',
            function(e) {
                $element.removeClass("is__static");
            });
            $("#mobile-nav").removeClass("is__open");
        }
        else
        {
            $(this).removeClass("is__static").addClass("is__open");
            $("#mobile-nav").addClass("is__open");
        }
    });


    $(document).on("click", "#project-roll-down, #floating-project-btn, .go-to-tiers-btn", function(){
        $("html, body").stop().animate({
            scrollTop : $("#project-heading-tiers").offset().top - 50
        });
    });

    var typingTimer;
    var doneTypingInterval = 1000;

    $(document).on("keyup", "#project-URL", function(){
        var projectcurrenturl = $("#currentURL").val();
        var projectnewurl = $("#project-URL").val();
        var input = $(this);

        clearTimeout(typingTimer);
        if (input.val()) {
            typingTimer = setTimeout(function(){
                if($.trim(projectnewurl) == "")
                {
                    $("#project-URL-response").text("URL cannot be left blank.").addClass("mt-_5");
                    $("#project-URL").attr("aria-invalid", true);
                    return;
                }

                if($.trim(projectnewurl) == projectcurrenturl)
                {
                    $("#project-URL-response").text("This is your current URL!").addClass("mt-_5");
                    $("#project-URL").attr("aria-invalid", false);
                    return;
                }

                var projectURLUpdate = new FormData();

                projectURLUpdate.set('projectURL', projectcurrenturl);
                projectURLUpdate.set('projectNewURL', projectnewurl);

                $.ajax({
                    url : '/p/checkprojectname',
                    data : projectURLUpdate,
                    method : 'POST',
                    processData: false,
                    contentType: false,
                    success : function(data){
                        if(data == "available")
                        {
                            $("#project-URL-response").html("This project URL is available!" + "</span><span class='icon text-success icon-sm ms-1'></span>").addClass("mt-_5").find(".icon").load(checkicon);
                            $("#project-URL").attr("aria-invalid", false);
                        }
                        else if(data == "unavailable")
                        {
                            $("#project-URL").attr("aria-invalid", true);
                            $("#project-URL-response").html("This URL is not available, try again.");
                        }
                        else if(data == "no access")
                        {
                            alert(data);
                        }
                        else
                        {
                            $("#project-URL").attr("aria-invalid", false);
                            $("#project-URL-response").html("");
                        }
                    }
                });
            }, doneTypingInterval);
        }
    });

    var urlFriendlyFunction;

    $(document).on("keyup", ".url-friendly", function(e){
        var input = $(this);

        clearTimeout(urlFriendlyFunction);

        if (input.val()) {
            urlFriendlyFunction = setTimeout(function(){
                input.val(input.val().toString()               // Convert to string
                                .normalize('NFD')               // Change diacritics
                                .replace(/[\u0300-\u036f]/g,'') // Remove illegal characters
                                .replace(/\s+/g,'-')            // Change whitespace to dashes
                                .toLowerCase()                  // Change to lowercase
                                .replace(/&/g,'-and-')          // Replace ampersand
                                .replace(/[^a-z0-9\-]/g,'')     // Remove anything that is not a letter, number or dash
                                .replace(/-+/g,'-')             // Remove duplicate dashes
                                .replace(/^-*/,'')              // Remove starting dashes
                                .replace(/-*$/,''));            // Remove trailing dashes
            }, doneTypingInterval/2);
        }
    });

    $(document).on("change", "#project-banner-img, #project-icon-img, #edit-tier-image, #tier-image", function(){
        var file = $(this);
        var reader = new FileReader();
        var img = new Image();

        var fileType = file[0].files[0]['type'];
        var fileSize = file[0].files[0].size;

        var validImageTypes = ['image/jpeg', 'image/png'];

        if (!validImageTypes.includes(fileType)) {
            alert("Oops, please use either a JPEG or PNG file type");
            file.val('');
            return;
        }

        if(fileSize > 2500000)
        {
            alert("Oops, file is too large, do not exceed 2.5 MB");
            file.val('');
            return;
        }

        reader.onload = function (e) {
            img.src = e.target.result;

            if(file.attr("id")  == "project-banner-img")
            {
                img.className = "img-fluid border";

                $("#banner-project-preview").html(img);

                var parent = file.closest(".position-relative");

                if(parent.find("a").hasClass("d-none"))
                {
                    parent.find("a").removeClass("d-none");
                    file.hide();
                }
            }
            else if(file.attr("id") == "project-icon-img")
            {
                img.className = "image-profile image-profile-md";

                $("#icon-project-preview").html(img);

                var parent = file.closest(".position-relative");

                if(parent.find("a").hasClass("d-none"))
                {
                    parent.find("a").removeClass("d-none");
                    file.hide();
                }
            }
            else if(file.attr("id")  == "edit-tier-image")
            {
                img.className = "img-fluid border";

                $("#banner-tier-preview").html(img);

                var parent = file.closest(".position-relative");

                if(parent.find("a").hasClass("d-none"))
                {
                    parent.find("a").removeClass("d-none");
                    file.hide();
                }
            }
            else if(file.attr("id")  == "tier-image")
            {
                img.className = "img-fluid border";

                $("#banner-tier-preview").html(img);

                var parent = file.closest(".position-relative");

                if(parent.find("a").hasClass("d-none"))
                {
                    parent.find("a").removeClass("d-none");
                    file.hide();
                }
            }
        };

        reader.readAsDataURL(file[0].files[0]);
    });

    $("a").on("click", function(e){
        if($(this).attr("href") == "#")
        {
            e.preventDefault();
        }
    });

    $(document).on("change", "[name=tier-active-switch]", function(){
        var fmdt = new FormData();
        var input = $(this);

        if(input.is(":checked"))
        {
            fmdt.set("tier-activation-value", "1");
        }
        else
        {
            fmdt.set("tier-activation-value", "0");
        }

        fmdt.set("tier-id", input.val());

        $.ajax({
            method: 'post',
            processData: false,
            contentType: false,
            cache: false,
            url: "/p/project-tier-activation",
            data : fmdt,
            success: function (response) {
                if(response == "no access")
                {
                    alert("You dont have access to make this change.")
                }
                else if(response != "success")
                {
                    if(input.is(":checked"))
                    {
                        input.prop("checked", false);
                    }
                    else
                    {
                        input.prop("checked", true);
                    }
                    alert("Something went wrong, try again.")
                }
            }
        });
    });

    // TODO: would like to move this to different entrypoint if time allows
    $(document).on("change", "[name=js-creator-email-sub-switch]", async function(){
        var fmdt = new FormData();
        var input = $(this);

        if(input.is(":checked")) {
            fmdt.set("email-sub-feature", "1");
        } else {
            fmdt.set("email-sub-feature", "0");
        }

        await EmailSubscriptionApi.enableProjectEmailSubscriptions(input.val(), fmdt)
            .done((response) => {
                // location.reload(); do we need to reload?
            }).fail((error) => {
                $('#email-sub-feature-error').removeClass('d-none');
                $('#email-sub-feature-error').html('Something went wrong')
                // TODO: worth displaying error message?
                console.log(error);
            });
    });

    $(document).on("submit", "#edit-project-details", function(e){
        e.preventDefault();
        e.stopPropagation();

        var form = new FormData(this);

        $.ajax({
            url: "/p/project-ajax-edit-details",
            data : form,
            method : 'post',
            success: function (response) {
                if(response.success)
                {
                    if(response.url)
                    {
                        window.location.href = response.url;
                    }
                }
                else
                {
                    alert(response.message);
                }
            },
            cache: false,
            contentType: false,
            processData: false
        })
    });

    $(document).on("click", "#delete-tier", function(e){
        e.preventDefault();
        e.stopPropagation();

        var btn = $(this);

        btn.append(loadingspinner).prop("disabled", true);

        var form = new FormData();

        form.set('tier-id', $("input[name=edit-tier-id]").val());

        $.ajax({
            url: "/p/delete-tier",
            data : form,
            method : 'post',
            success: function (response) {
                btn.find('.spinner-border').remove();
                btn.prop("disabled", false);

                if(response.success)
                {
                    window.location.href = response.url;
                }
                else
                {
                    alert_el.addClass('alert alert-info show').text(response.message).append(alert_button);
                    $("#edit-tier-details").prepend(alert_el);
                }
            },
            cache: false,
            contentType: false,
            processData: false
        });
    });

    $(document).on("submit", "#edit-tier-details", function(e){
        e.preventDefault();
        e.stopPropagation();

        var form = new FormData(this);

        form.set("edit-tier-description", $("[name=edit-tier-description]").val().replace(/\r\n|\r|\n/g,"<br />"))

        $.ajax({
            url: "/p/edit-tier-details",
            data : form,
            method : 'post',
            success: function (response) {
                if(response.success)
                {
                    if(response.url)
                    {
                        window.location.href = response.url;
                    }
                }
                else
                {
                    alert(response.message);
                }
            },
            cache: false,
            contentType: false,
            processData: false
        });
    });

    $(document).on("submit", "#edit-fund-goal-details", function(e){
        e.preventDefault();
        e.stopPropagation();

        var form = new FormData(this);

        form.set("edit-goal-description", $("[name=edit-goal-description]").val().replace(/\r\n|\r|\n/g,"<br />"));

        $.ajax({
            url: "/p/edit-goal-details",
            data : form,
            method : 'post',
            success: function (response) {
                if(response.success)
                {
                    if(response.url)
                    {
                        window.location.href = response.url;
                    }
                }
                else
                {
                    alert(response.message);
                }
            },
            cache: false,
            contentType: false,
            processData: false
        });
    });

    $(document).on("click", "#delete-goal", function(e){
        e.preventDefault();
        e.stopPropagation();

        var btn = $(this);

        btn.append(loadingspinner).prop("disabled", true);

        var form = new FormData();

        form.set('edit-goal-id', $("input[name=edit-goal-id]").val());

        $.ajax({
            url: "/p/delete-goal",
            data : form,
            method : 'post',
            success: function (response) {
                btn.find('.spinner-border').remove();
                btn.prop("disabled", false);

                if(response.success)
                {
                    window.location.href = response.url;
                }
                else
                {
                    alert_el.addClass('alert alert-info show').text(response.message).append(alert_button);
                    $("#edit-tier-details").prepend(alert_el);
                }
            },
            cache: false,
            contentType: false,
            processData: false
        });
    });

    $(document).on("change", "input[name='tier-selection-change']", function(){
        var amountDefault = $("#sub-amount-default").val();
        var tierDefault = $("#sub-tier-default").val();

        var amount = $("#subscription-change-amount").val().replace(/[^0-9]/g, '');
        var tier = $(this).val();
        var min = $(this).closest(".card").find(".tier-price").text().replace(/[^0-9]/g, '');

        if(min == "")
        {
            min = 1;
        }

        $("#subscription-change-amount").attr('data-amount-minimum', min);

        $("#subscription-change-amount").inputmask(
            'decimal',
                { 'alias': 'numeric',
                    'groupSeparator': ',',
                    'autoGroup': true,
                    'digits': 0,
                    'radixPoint': ".",
                    'digitsOptional': false,
                    'allowMinus': false,
                    'prefix': '$',
                    'placeholder': '0',
                    'rightAlign': false,
                    'nullable' : false,
                    'min' : min
                }
        );

        if(tierDefault == tier)
        {
            $("#subscription-change-amount").val(amountDefault);
            $("#update-subscription").prop("disabled",true);
        }
        else
        {
            $("#update-subscription").prop("disabled",false);
        }
    });

    $(document).on("keyup", "#subscription-change-amount", function(){
        var amountDefault = $("#sub-amount-default").val();
        var tierDefault = $("#sub-tier-default").val();

        var amount = $("#subscription-change-amount").val().replace(/[^0-9]/g, '');
        var tier = $("input[name='tier-selection-change']:checked").val();

        if(tierDefault == tier && amountDefault == amount)
        {
            $("#update-subscription").prop("disabled", true);
        }
        else
        {
            $("#update-subscription").prop("disabled",false);
        }

        clearTimeout(timeout);

        timeout = setTimeout(function () {
            $("#subtotal-change-amount, #total-change-amount").text($("#subscription-change-amount").val());
        }, 500);
    }).on("click", "#update-subscription", function(){
        var btn = $(this);
        var form = new FormData();

        form.set("sub-id", $("#sub-id").val());
        form.set("order-id", $("#order-id").val());
        var qnty = null;

        if($("input[name=subscription-change-amount]").length)
        {
            qnty = $("input[name=subscription-change-amount]").val().replace(/[^0-9]/g, '');
        }
        form.set("amount-change", qnty);
        form.set("tier-id", $("#tier-id").val());

        btn.prop("disabled", true).append(loadingspinner);

        $.ajax({
            url : '/p/update-subscription',
            data : form,
            method : 'POST',
            processData: false,
            contentType: false,
            success : function(data){
                btn.prop("disabled", false).find('.spinner-border').remove();

                if(data == "wrong sub")
                {

                }
                else if(data == "wrong tier")
                {

                }
                else if(data == "alreadysubbed")
                {

                }
                else{
                    window.location.href = data;
                }
            }
        });
    });

    $(document).on("click", ".default-card", function(){
        var parentBtn = $(this);

        parentBtn.append(loadingspinner).prop('disabled', true);

        var form = new FormData();
        form.set('card-id', $(this).attr("data-card-id"));

        $.ajax({
            url : '/p/account/defaultcard',
            data : form,
            method : 'POST',
            processData: false,
            contentType: false,
            success : function(data){
                if(data['1'] == "success")
                {
                    if($("#card-wallet-insert").length)
                    {
                        $("#card-wallet-insert").load("/p/account/wallet-partial");
                    }
                    else if($("#cards-container-subscribe").length)
                    {
                        $("#cards-container-subscribe").load("/p/account/wallet-partial");
                        var last4 = parentBtn.closest(".card-container").find(".last4").text();
                        var icon = parentBtn.closest(".card-container").find(".icon").clone();

                        var cardId = parentBtn.attr("data-card-id");

                        $("#payment-method-source").attr("data-card-id", cardId);

                        if(data['2'] == true)
                        {
                            $("#alert-update-address").remove();
                            $("#subscribe-to-service").prop("disabled", false);
                        }
                        else{
                            if(!$("#alert-update-address").length)
                            {
                                $("#payment-method-source").after('<div id="alert-update-address" class="alert alert-danger"><a href="#" id="modal-toggle-update-address" data-bs-toggle="modal" role="button" data-bs-target="#update-card-address" class="alert-link" data-card-id="{{card.id}}">You must add a billing address to this card before you can subscribe.</a></div>');
                            }
                            $("#subscribe-to-service").prop("disabled", true);
                        }

                        $("#payment-method-source").find('span').last().text(last4);
                        $("#payment-method-source").find('.icon').remove();
                        $("#payment-method-source").prepend(icon);
                    }
                }
            }
        });
    });

    $(document).on("click", '[data-change-text]', function(){
        var raw = $(this).attr('data-change-text').split(",");

        if($(this).text() == raw[0])
        {
            $(this).text(raw[1]);
        }
        else
        {
            $(this).text(raw[0]);
        }
    })

    $(document).on("click", "#save-edit-card-details", function(){
        var parentBtn = $(this);

        parentBtn.append(loadingspinner).prop('disabled', true);

        var id = $(this).attr("data-card-id");

        var cardexpmonth = $("#card-exp-month").val();
        var cardexpyear = $("#card-exp-year").val();

        var billingcountry = $("#billing-country-edit").val();
        var billingaddress1 = $("#billing-address-1-edit").val();
        var billingaddress2 = $("#billing-address-2-edit").val();
        var billingcity = $("#billing-city-edit").val();
        var billingstate = $("#billing-state-edit").val();
        var billingzipcode = $("#billing-zipcode-edit").val();

        var form = new FormData();

        form.set("card-id", id);

        form.set("card-exp-month", cardexpmonth);
        form.set("card-exp-year", cardexpyear);

        form.set("card-billing-country", billingcountry);
        form.set("card-billing-address-1", billingaddress1);
        form.set("card-billing-address-2", billingaddress2);
        form.set("card-billing-city", billingcity);
        form.set("card-billing-state", billingstate);
        form.set("card-billing-zipcode", billingzipcode);

        $.ajax({
            url : '/p/account/editcard',
            data : form,
            method : 'POST',
            processData: false,
            contentType: false,
            success : function(data){
                parentBtn.prop('disabled', false).children(".spinner-border").remove();

                if(data == "fill info")
                {
                    alert("Make sure to fill in all details to update card");
                }
                else if(data == "Expiration must be number")
                {
                    alert("The expiration date must be a number");
                }
                else if(data == "card updated")
                {
                    alert_el.text("Your card has been updated.").removeClass("alert-danger alert-info alert-success").addClass("alert-success");
                    alert_el.append(alert_button).addClass('fade show');
                    $("#editCardModal .card-container").before(alert_el);
                    $('#edit-card-address-form')[0].reset();
                    $("#card-exp-month, #card-exp-year").val("");
                    $("#editCardModal .card-container").html("");
                }
                else{
                    alert(data);
                }
            }
        });
    });

    if($("#cancel-confirmation-modal").length)
    {
        var modalCancel = document.getElementById('cancel-confirmation-modal')
        modalCancel.addEventListener('show.bs.modal', function (event) {
            $("#cancel-confirmation-modal .modal-body").html(loadingspinner);
            var subId = $(event.relatedTarget).attr("data-subscription-id");
            var subId2 = $(event.relatedTarget).attr("data-subscription-id");
            $("#cancel-confirmation-modal .modal-body").load("/p/account/cancelconfirmation/"+subId, function(){
                $("#cancel-subscription-btn").attr("data-cancel-sub", subId2);
            });
        });

        modalCancel.addEventListener('hidden.bs.modal', function(event){
            $("#cancel-subscription-btn").removeAttr("data-cancel-sub");
        });

        $(document).on("click", "#cancel-subscription-btn", function(){
            var btn = $(this);

            btn.prop("disabled", true).append(loadingspinner);

            var form = new FormData();

            form.set("cancel-id", btn.attr("data-cancel-sub"));

            $.ajax({
                url : '/p/account/cancel-sub',
                data : form,
                method : 'POST',
                processData: false,
                contentType: false,
                success : function(data){
                    btn.prop("disabled", false).find(".spinner-border").remove();

                    if(data == "success")
                    {
                        $("#subs-container").load("/p/account/getupdatedsubs");
                        var myModal = bootstrap.Modal.getInstance(modalCancel);
                        myModal.hide();
                        alert_el.text("You successfully cancelled your subscription").removeClass("alert-danger alert-info alert-success").addClass("alert-success");
                        alert_el.append(alert_button).addClass('fade show');
                        $("#content-area").before(alert_el);
                    }
                }
            });
        }).on("click", ".subscription-reactivate", function(){
            var btn = $(this);

            btn.prop("disabled", true).append(loadingspinner);

            var form = new FormData();

            form.set("reactivate-id", btn.attr("data-subscription-id"));

            $.ajax({
                url : '/p/account/reactivate-sub',
                data : form,
                method : 'POST',
                processData: false,
                contentType: false,
                success : function(data){
                    btn.prop("disabled", false).find(".spinner-border").remove();

                    if(data == "success")
                    {
                        $("#subs-container").load("/p/account/getupdatedsubs");
                        alert_el.text("You successfully reactivated your subscription").removeClass("alert-danger alert-info alert-success").addClass("alert-success");
                        alert_el.append(alert_button).addClass('fade show');
                        $("#content-area").before(alert_el);
                    }
                    else
                    {
                        alert_el.text("Oops, something happened, try again.").removeClass("alert-danger alert-info alert-success").addClass("alert-error");
                        alert_el.append(alert_button).addClass('fade show');
                        $("#content-area").before(alert_el);
                    }
                }
            });
        });
    }

    if($("#manage-sub-modal").length)
    {
        var myModalEl = document.getElementById('manage-sub-modal')
        myModalEl.addEventListener('show.bs.modal', function (event) {
            $("#manage-sub-modal .modal-body").html(loadingspinner);
            var subId = $(event.relatedTarget).attr("data-subscription-id");
            $("#manage-sub-modal .modal-body").load("/p/account/manage-sub-partial/"+subId, function(){
                $("[data-type=currency]").each(function(){
                    $(this).inputmask(
                        'decimal',
                            { 'alias': 'numeric',
                                'groupSeparator': ',',
                                'autoGroup': true,
                                'digits': 0,
                                'radixPoint': ".",
                                'digitsOptional': false,
                                'allowMinus': false,
                                'prefix': '$',
                                'placeholder': '0',
                                'rightAlign': false,
                                'nullable' : false,
                                'min' : this.hasAttribute('data-amount-minimum') ? $(this).attr('data-amount-minimum') : 1
                            }
                    );
                });
            });
        });
    }

    $(".drag-drop-file .dd-link-trigger").on("click", function(e){
        e.preventDefault();

        $(this).closest(".drag-drop-file").find("input[type=file]").trigger("click");
    });

    // if($("#user-feed").length)
    // {
    //     $(window).scroll(function(){
    //         if($(this).scrollTop() > $("#user-feed").height() + $("#user-feed").position().top)
    //         {
    //             console.log($(this).scrollTop() + ", " + $("#user-feed").height() + $("#user-feed").position().top)
    //         }
    //     });
    // }

    $("[data-input-trigger]").on("click", function(){
        var triggeredInput = $(this).attr("data-input-trigger");

        $(triggeredInput).click();
    });
});

var isAdvancedUpload = function() {
 var div = document.createElement('div');
 return (('draggable' in div) || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window;
}();

if(!isAdvancedUpload){
    $(".drag-drop-file").addClass("feature-disabled");
}

$(document).on('drag dragstart dragend dragover dragenter dragleave drop', '.drag-drop-file', function(e){
    e.preventDefault();
    e.stopPropagation();
}).on("dragover dragenter", '.drag-drop-file', function(){
    $(this).addClass("is-drag-over");
}).on("dragleave dragend drop", '.drag-drop-file', function(){
    $(this).removeClass("is-drag-over");
}).on("drop", '.drag-drop-file', function(e){
    var fileInput = $(this).find('input[type=file]');
    var attr = $(this).find("input").attr("multiple");

    if(typeof attr !== 'undefined' && attr !== false)
    {
        var droppedFile = e.originalEvent.dataTransfer.files[0];
    }
    else
    {
        var droppedFile = e.originalEvent.dataTransfer.files;
    }

    fileInput.prop("files", droppedFile);
    fileInput.trigger("change");
});

$(document).on('change', '.drag-drop-file input[type=file]', function(){
    var file = $(this);
    var reader = new FileReader();
    var img = new Image();
    var parent = $(this).closest(".drag-drop-file");

    var fileType = file[0].files[0]['type'];
    var fileSize = file[0].files[0].size;

    var validImageTypes = ['image/jpeg', 'image/png', 'image/gif'];

    if (!validImageTypes.includes(fileType)) {
        alert("Oops, please use either a JPEG, PNG or GIF file type");
        file.val('');
        return;
    }

    if(fileSize > 2500000)
    {
        alert("Oops, file is too large, do not exceed 2.5 MB");
        file.val('');
        return;
    }

    reader.onload = function (e) {
        img.src = e.target.result;
        img.className = "dd-preview-img";

        if($(".dd-preview-img").length)
        {
            $(".dd-preview-img").remove();
        }
        parent.append(img);
        parent.addClass('image-displayed');
        parent.append("<button class='dd-close position-absolute top-0 end-0 icon me-2 mt-2 icon-xlg btn-close btn-close-circular'></button>");
    };

    reader.readAsDataURL(file[0].files[0]);
});

function delay(fn, ms) {
  let timer = 0
  return function(...args) {
    clearTimeout(timer)
    timer = setTimeout(fn.bind(this, ...args), ms || 0)
  }
}

$("#post-video-embed, #post-live-embed, #post-audio-embed, #post-podcast-embed").typeWatch({
    callback: function(value){
        var input = $(this);

        if(this.id == "post-video-embed" || this.id == "post-live-embed")
        {
            input.removeClass("is-invalid").siblings(".invalid-feedback").removeClass("is-invalid");

            if(!value)
            {
                $("#post-video-preview, #post-live-preview").addClass("d-none").html("");
                $(this).removeClass("is-invalid").siblings(".invalid-feedback").text("").removeClass('is-invalid');
                return;
            }
            else if(!validURL(value))
            {
                $(this).addClass("is-invalid").siblings(".invalid-feedback").text("Oops, that's not a valid URL!").addClass('is-invalid');
                return;
            }

            if (value.indexOf('youtube') !== -1) {
                function getId(url) {
                    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
                    const match = url.match(regExp);

                    return (match && match[2].length === 11)
                      ? match[2]
                      : null;
                }

                var embedHtml = '<div class="post-video-wrapper"><iframe width="100%" height="auto" src="//www.youtube.com/embed/'+getId(value)+'" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>';

                $("#post-video-preview, #post-live-preview").removeClass("d-none").html(embedHtml);
                $(this).removeClass("is-invalid").siblings(".invalid-feedback").text("").removeClass('is-invalid');
            }
            else
            {
                $(this).addClass("is-invalid").siblings(".invalid-feedback").text("Oops, that's not a youtube link!").addClass('is-invalid');
                return;
            }
        }
        else if(this.id == "post-audio-embed" || this.id == "post-podcast-embed")
        {
            $("#post-audio-preview").removeClass("d-none").html(value);
            $(this).removeClass("is-invalid").siblings(".invalid-feedback").text("").removeClass('is-invalid');
        }
    },
    wait : 500,
});

$(document).on("load", function(){
    if($("#post-video-preview").length)
    {
        var value = $("#post-video-preview").text();

        if (value.indexOf('youtube') !== -1) {
            function getId(url) {
                const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
                const match = url.match(regExp);

                return (match && match[2].length === 11)
                  ? match[2]
                  : null;
            }

            var embedHtml = '<div class="post-video-wrapper"><iframe width="100%" height="auto" src="//www.youtube.com/embed/'+getId(value)+'" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>';

            $("#post-video-preview").removeClass("d-none").html(embedHtml);
        }
        else
        {
            $(this).addClass("is-invalid").siblings(".invalid-feedback").text("Oops, that's not a youtube link!").addClass('is-invalid');
            return;
        }
    }
});

$(document).on("click", ".dd-close", function(){
    var parent = $(this).closest(".drag-drop-file");

    $(this).remove();
    parent.find('.dd-preview-img').remove();
    parent.removeClass('image-displayed');
    parent.find('input[type=file]').val('');
});

var updateCardDetailsModal = document.getElementById('update-card-address');

if($("#update-card-address").length)
{
    updateCardDetailsModal.addEventListener('show.bs.modal', function (event) {
      var cardinfo = $('#payment-method-source').clone();
      $("#card-preview").html(cardinfo.attr("id", "payment-method-source-alt"));
    });
}



$(window).on("load", function(){
    if($("#tier-selector").length)
    {
        var collapseTiers = document.getElementById("tier-selector");
        var collapse = new bootstrap.Collapse(collapseTiers,{ toggle : false});

        $("input[type=radio][name=post-supporter-type]").on("change", function(){
            if($(this).val() == "selectTiers")
            {
                collapse.show();
            }
            else
            {
                collapse.hide();
            }
        });
    }
})

var scrollOriginal = 0;



$(window).scroll(function(){
    if($("header.is__interactive").length && $("header:not(.is__shown)"))
    {
        if($(window).scrollTop() > scrollOriginal)
        {
            $("header").addClass("is__shown");
        }
    }

    if($("#posts-list").length > 0 && $(window).scrollTop() > $("#posts-list").offset().top)
    {
        $("#project-subscribe-button").removeClass("d-none");
    }
    else
    {
        $("#project-subscribe-button").addClass("d-none");
    }
});

$(document).on("change", "#profilePicture-upload", function(){
    var file = $(this);
    var reader = new FileReader();
    var img = new Image();

    var fileType = file[0].files[0]['type'];
    var fileSize = file[0].files[0].size;

    var validImageTypes = ['image/jpeg', 'image/png'];

    if (!validImageTypes.includes(fileType)) {
        alert("Oops, please use either a JPEG or PNG file type");
        file.val('');
        return;
    }

    if(fileSize > 2500000)
    {
        alert("Oops, file is too large, do not exceed 2.5 MB");
        file.val('');
        return;
    }

    reader.onload = function (e) {
        img.src = e.target.result;
        img.className = "w-100";

        $("#profile-preview").append(img);
        $("#profile-empty-container, #profilepicture-account").hide();
    };

    reader.readAsDataURL(file[0].files[0]);

    reader.onloadend = function(){
        const cropper = new Cropper(img, {
            aspectRatio: 1 / 1,
            movable : false,
            rotatable : false,
            scalable: false,
            zoomable : false,
            zoomOnTouch : false,
            zoomOnWheel : false,
            viewMode : 1
        });

        $("#profile-upload").removeClass("d-none").on("click", function(){
            var cropData = cropper.getData();

            var form = new FormData();

            form.set('image', file[0].files[0]);
            form.set('cropData', JSON.stringify(cropData));

            $.ajax({
                url : '/p/account/uploadprofile',
                data : form,
                method : 'POST',
                processData: false,
                contentType: false,
                success : function(data){
                    if(data == "success")
                    {
                        var newDiv = $("<div id='profilepicture-account' class='image-profile image-profile-lg mx-auto' />");
                        var imgTemp = new Image();
                        var headerimg = new Image();

                        newDiv.append(imgTemp);

                        cropper.getCroppedCanvas().toBlob((blob) =>{
                            var url = URL.createObjectURL(blob);
                            $("#profile-empty-container").hide().before(newDiv);
                            imgTemp.src = url;
                            headerimg.src = url;

                            cropper.destroy();
                            img.remove();

                            $(".navbar .image-profile").append(headerimg);

                            location.reload();
                        })
                    }
                    else if(data == "file type fail")
                    {
                        alert("Oops, please use either a JPEG or PNG file type");
                        cropper.destroy();
                        $("#profile-upload, #profile-cancel-upload").addClass("d-none");
                        $("#profile-preview").find("img").remove();
                        $("#profile-empty-container").show();
                    }
                    else if(data == "failed upload")
                    {
                        alert("Oops, the upload failed. Try again.");
                    }

                    else if(data == "file too large")
                    {
                        alert("Oops, file is too large, try another photo");
                        cropper.destroy();
                        $("#profile-upload, #profile-cancel-upload").addClass("d-none");
                        $("#profile-preview").find("img").remove();
                        $("#profile-empty-container").show();
                    }
                }
            });
        });

        $("#profile-cancel-upload").removeClass("d-none").on("click", function(){
            cropper.destroy();
            $("#profile-upload, #profile-cancel-upload").addClass("d-none");
            $("#profile-preview").find("img").remove();
            $("#profile-empty-container").show();
        });
    }
});

$("#remove-photo").on("click", function(){
    $.ajax({
        url : '/p/account/removeprofile',
        method : 'POST',
        processData: false,
        contentType: false,
        success : function(data){
            if(data == "success")
            {
                location.reload();
            }
        }
    })
});

$("#update-password-submit").on("click", function(){
    var oldpw = $("#old-password-confirmation").val();
    var newpw = $("#new-password-confirmation").val();

    if($.trim(oldpw) == "" && $.trim(newpw) == "")
    {
        alert("Fill out the password fields");
        return;
    }

    var form = new FormData();

    form.set("password-update-initiation", oldpw);
    form.set("newpw", newpw);
    form.set("token", $("#token-password-change").val());

    $.ajax({
        url : '/p/account/updateaccountinfo',
        method : 'POST',
        data : form,
        processData: false,
        contentType: false,
        success : function(data){
            if(data == "password updated")
            {
                alert("Your password was updated.");
                $("#new-password-confirmation, #old-password-confirmation").val("");
            }
        }
    });
});

$("#update-email-submit").on("click", function(){
    var currentpw = $("#confirmpassword").val();
    var newemail = $("#changeEmailInput").val();
    var token = $("#token-email").val();

    var form = new FormData();

    form.set("update-email-account", newemail);
    form.set("password", currentpw);
    form.set("token", token);

    $.ajax({
        url : '/p/account/updateaccountinfo',
        method : 'POST',
        data : form,
        processData: false,
        contentType: false,
        success : function(data){
            if(data == "success")
            {
                alert("Your new email was set for your account. A confirmation email was sent too.");
                $("#user-email-address").text(newemail);

                $("#confirmpassword").val("");
                $("#changeEmailInput").val("");
            }
            else if(data == "invalid email")
            {
                alert("Your email is invalid");
            }
            else if(data == "wrong pw")
            {
                alert("Wrong password, try again!");
                $("#confirmpassword").val("").focus();
            }
        }
    });
});

$("#update-displayname-submit").on("click", function(){
    var displayname = $("#displayname").val();
    var token = $("#token-displayname").val();

    var form = new FormData();

    form.set("displayname", displayname);
    form.set("token", token);

    $.ajax({
        url : '/p/account/updateaccountinfo',
        method : 'POST',
        data : form,
        processData: false,
        contentType: false,
        success : function(data){
            if(data == "success")
            {
                alert("Your display name was successfully changed!");
                $("#user-display-name, #user-display-name-header").text(displayname);
            }
        }
    });
});

$("#connect-account-stripe").on("click", function(){
    var form = new FormData();

    form.set("connect-account-stripe", "");

    $.ajax({
        url : '/p/account/updateaccountinfo',
        method : 'POST',
        data : form,
        processData: false,
        contentType: false,
        success : function(data){
            if(data == "already connected")
            {
                alert("You already have an account!");
            }
            else
            {
                location.replace(data);
            }
        }
    });
});

var canStillLoadMore = true;
var scrollTimer;
var lastScroll = 0;
var isLoadingRows = false;

$(window).on("scroll", function(){
    if($("#subAdminPage").length)
    {
        if(canStillLoadMore)
        {
            clearTimeout(scrollTimer);
            if($(window).scrollTop() + $(window).height() >= $(document).height() - 100 && lastScroll < $(window).scrollTop() && isLoadingRows == false)
            {
                $(".loading-area").removeClass('d-none');

                scrollTimer = setTimeout(function(){
                    isLoadingRows = true;
                    var form = new FormData();
                    var numRows = $(".sub-row-info").length;

                    form.set("offsetAmount", numRows);

                    $.ajax({
                        url : '/p/admin/ajax-subscriptions',
                        method : 'POST',
                        data : form,
                        processData: false,
                        contentType: false,
                        success : function(data){
                            if(data.message == "invalid number")
                            {
                                canStillLoadMore = false;
                            }
                            else if(data.message == "nomorescroll")
                            {
                                canStillLoadMore = false;
                            }

                            var month = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
                            
                            $(data.data).each(function(index){
                                var isLastElement = index == $(data.data).length -1;

                                var project = $(this)[1].project;
                                var supporter = $(this)[1].supporter;
                                var price = $(this)[1].price;
                                var status = '<span class="badge rounded-pill '+ ($(this)[0].status == 'active' ? 'bg-success' : 'bg-danger') +'">' + ($(this)[0].status == 'active' ? $(this)[0].status : 'failed') + '</span>';
                                var lastBilling = $(this)[1].lastBilling;
                                var nextBilling = $(this)[1].nextBilling;
                                var subID = $(this)[1].subscriptionId;

                                $("#subscription-table").append(
                                    "<tr class='sub-row-info'><td>" + project + "</td><td>" + supporter + "</td><td>" + price + "</td><td>" + status + "</td><td>" + lastBilling + "</td><td>" + nextBilling + "</td><td>" + subID + "</td></tr>"
                                );
                            });

                            $(".maxNumRows").text(data.maxRows);
                            $(".loadedNumRows").text($(".sub-row-info").length);
                            $(".loading-area").addClass('d-none');
                            isLoadingRows = false;
                        }
                    });
                }, 500);
            }

            lastScroll = $(window).scrollTop();
        }
    }
});

$(document).on("click", "#create-creator-project", function(e){
    e.preventDefault();
    e.stopPropagation();
    var button = $(this);

    if($("input[name=token-creator-process-start").length)
    {
        var formData = new FormData();
        formData.set("creator-creation-token", $("input[name=token-creator-process-start]").val());

        button.append(loadingspinner).prop('disabled', true);

        $.ajax({
            url : "/p/start_creator_process",
            method : 'POST',
            processData: false,
            contentType: false,
            success : function(m){
                button.prop("disabled", false).find(".spinner-border").remove();
                
                console.log(m);
                if(m.returnType == "success")
                {
                    window.location.href = m.url;
                }
            }
        });
    }
});

function urlify(element, e){
    var key = e.keyCode || e.which;
    var val = element.val();

    if(key == 37 || key == 38 || key == 39 || key == 40 || key == 8 || key == 46 || key == 9)
    {
        return;
    }

    if(key == 32)
    {
        e.preventDefault();

        if(val.length == 0)
        {
            return;
        }

        if(val[val.length-1] == "-")
        {
            return;
        }

        element.val(val+"-");
    }

    key = String.fromCharCode(key);
    if(/[a-zA-Z0-9/s]/i.test(key) === false) e.preventDefault();
}

var progressBar = document.querySelector(".progress-bar");
var scrollPosition = window.scrollY;

if(progressBar)
{
    window.addEventListener('scroll', function() {
        var barPos = progressBar.getBoundingClientRect().top + window.pageYOffset;

        scrollPosition = window.scrollY;

        if (progressBar && scrollPosition + window.screen.height >= barPos) {
            progressBar.classList.add('start');
        }

    });
}